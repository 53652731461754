import dayjs from 'dayjs'

// 数据类型文案map
export const SpecTypeMap = new Map<DataTypeOfType, string>([
  ['BOOL', '布尔类型'],
  ['DATE', '时间戳'],
  ['DAY', '日期'],
  ['DECIMAL', '浮点型'],
  ['ENUM', '枚举型'],
  ['INT', '数字类型'],
  ['TEXT', '字符串类型'],
  ['ARRAY', '数组类型'],
  ['STRUCT', '结构体类型'],
])

export const ThingModelEventTypeMap = new Map<EventType, string>([
  ['INFO', '信息'],
  ['ALERT', '告警'],
  ['ERROR', '故障'],
])

export const ThingModelEventTypeColorMap = new Map<EventType, string>([
  ['INFO', 'success'],
  ['ALERT', 'warning'],
  ['ERROR', 'error'],
])

export const InitDataOfDataTypeMap = new Map<DataTypeOfType, any>([
  ['INT', 0],
  ['DECIMAL', 0.0],
  ['TEXT', ''],
  ['ENUM', undefined],
  ['BOOL', false],
  ['DATE', dayjs().format('YYYY-MM-DD HH:mm:ss')],
  ['DAY', dayjs().format('YYYY-MM-DD')],
  ['ARRAY', []],
  ['STRUCT', {}],
])

export const AccessModeTypeMap = new Map<AccessModeType, { text: string, color: string }>([
  ['READ_ONLY', { text: '只读', color: 'blue' }],
  ['READ_WRITE', { text: '读写', color: 'green' }],
])
